<template>
  <div class="mod-config">
     

     <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
      <table class="detailTable_search" style="width: 100%">
        <tr>
          <td class="titleTd width80px">{{ $t('config.paramName') }}</td>
          <td class="valueTd">
            <el-input v-model="dataForm.paramKey" :placeholder="$t('config.paramName')" clearable></el-input>
          </td> 
        </tr>
      </table>
    </el-form>
    
    <div class="wxts_msg_search" >
      <div class="f_t">
        <el-button-group  >
        <el-button size="mini" type="success" @click="search()" :disabled="dataListLoading">{{ $t('query') }}</el-button>
        <el-button size="mini" v-if="isAuth('sys:config:save')" type="primary" @click="addOrUpdateHandle()" :disabled="dataListLoading">{{ $t('add') }}</el-button>
        <el-button size="mini" v-if="isAuth('sys:config:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0 || dataListLoading">{{ $t('deleteBatch') }}</el-button>
        </el-button-group>
      </div>
    </div>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="paramKey"
        header-align="center"
        align="center"
        :label="$t('config.paramName')"
      >
      </el-table-column>
      <el-table-column
        prop="paramValue"
        header-align="center"
        align="center"
        :label="$t('config.paramValue')"
      >
      </el-table-column>
      <el-table-column
        prop="remark"
        header-align="center"
        align="center"
        :label="$t('config.remark')"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="150"
        :label="$t('handle')"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            class="update-button"
            size="small"
            @click="addOrUpdateHandle(scope.row.paramKey)"
            >{{ $t('update') }}</el-button
          >
          <el-button
            type="text"
            class="del-button"
            size="small"
            @click="deleteHandle(scope.row.paramKey)"
            >{{ $t('delete') }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mod-footer"
      v-if="!dataListLoading"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./add-or-update";
import { getList,deleteConfig} from '@/api/sys/config'
export default {
  components: {
    AddOrUpdate
  },
  data() {
    return {
      dataForm: {
        paramKey: ""
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    };
  },

  activated() {
    this.dataForm.paramKey = this.$route.query.key;
    let pageIndex = this.$route.query.pageIndex;

    if (pageIndex) {
      this.pageIndex = parseInt(pageIndex);
    }

    let pageSize = this.$route.query.pageSize;

    if (pageSize) {
      this.pageSize = parseInt(pageSize);
    }

    this.getDataList();
  },
  methods: {
    refresh() {
      this.$router.push({
        name: "sys_config",
        query: {
          key: this.dataForm.paramKey,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        }
      });
    },
    search() {
      this.pageIndex = 1;
      this.getDataList();
      this.refresh();
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true; 
      getList({
          page: this.pageIndex,
          limit: this.pageSize,
          paramKey: this.dataForm.paramKey
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalCount = data.data.totalCount;
          if(data.data.page != this.pageIndex) {
            this.pageIndex = data.data.page
          }
        } else {
          this.dataList = [];
          this.totalCount = 0;
          this.pageIndex = 1
        }
        
      }).finally(()=>{
        this.dataListLoading = false
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
      this.refresh();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
      this.refresh();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });

      // if(id) {
      //   this.$router.push({name:'sys_config_update' , query:{id:id}})
      // } else{
      //   this.$router.push({name:'sys_config_add'})
      // }
    },
    // 删除
    deleteHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map(item => {
            return item.paramKey;
          });

      this.$confirm(this.$t('config.confirmDelete'), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: "warning"
      }).then(() => {
       
        deleteConfig(ids).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: this.$t('prompt.success'),
                type: "success",
                duration: 1000,
                onClose: () => {
                  this.getDataList();
                }
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
