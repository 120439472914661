<template>
  <el-dialog
    :title="!dataForm.id ? $t('config.addConfigParameter') : $t('config.updateConfigParameter')"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :append-to-body="true"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="80px"
      v-loading="loading"
    >
      <el-form-item :label="$t('config.type')" prop="paramType">
        <el-radio-group
          v-model="dataForm.paramType"
          :disabled="dataForm.id != null"
        >
          <el-radio
            v-for="item in paramTypes"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('config.paramName')" prop="paramKey">
        <el-input
          v-model="dataForm.paramKey"
          :placeholder="$t('config.paramName')"
          :disabled="dataForm.id != null"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('config.paramValue')"
        prop="paramValue"
        v-if="dataForm.paramType == 1"
      >
        <el-input
          v-model="dataForm.paramValue"
          type="textarea"
          :placeholder="$t('config.paramValue')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('config.paramValueArr')"
        prop="paramValue"
        v-if="dataForm.paramType == 2"
      >
        <el-row>
          <el-col :span="20">
            <el-row v-for="(item, index) in dataForm.paramList" :key="index">
              <el-col :span="15">
                <el-input v-model="dataForm.paramList[index]">{{
                  item
                }}</el-input>
              </el-col>
              <el-col :span="4">
                <el-button
                  type="text"
                  size="small"
                  @click="deleteListHandle(index)"
                  >{{ $t('delete') }}</el-button
                >
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="addListCol" size="mini"
              >{{ $t('config.addValue') }}</el-button
            >
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('config.paramValueTab')"
        prop="paramValue"
        v-if="dataForm.paramType == 3"
      >
        <el-row>
          <el-col :span="20">
            <el-checkbox-group v-model="deleteRows">
              <el-checkbox
                v-for="item in dataForm.paramTableKyes"
                :label="item"
                :key="item"
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
          </el-col>
          <el-col :span="4">
            <el-button
              v-if="dataForm.paramTableKyes.length > 0"
              type="danger"
              @click="delCol"
              size="mini"
              >{{ $t('config.delColumn') }}</el-button
            >
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-table
              :data="dataForm.paramTable"
              border
              height="250"
              fixed
              style="width: 100%"
            >
              <el-table-column
                :show-overflow-tooltip="true"
                v-for="item in dataForm.paramTableKyes"
                :prop="item"
                :key="item"
                :label="item"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row[item]"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                header-align="center"
                align="center"
                :label="$t('handle')"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="deleteTableHandle(scope.$index)"
                    >{{ $t('delete') }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-button type="primary" @click="addCol" size="mini"
                >{{ $t('config.addColumn') }}</el-button
              >
            </el-row>

            <el-row>
              <el-button type="primary" @click="addRow" size="mini"
                >{{ $t('config.addRow') }}</el-button
              >
            </el-row>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('config.remark')" prop="remark">
        <el-input v-model="dataForm.remark" :placeholder="$t('config.remark')"></el-input>
      </el-form-item>
    </el-form>
    <div class="mod-footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" :disabled="loading" @click="dataFormSubmit()"
        >{{ $t('confirm') }}</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { getById , saveOrUpdate } from '@/api/sys/config'
export default {
  data() {
    var validateValue = (rule, value, callback) => {
      if (
        this.dataForm.paramType == 1 &&
        !/\S/.test(this.dataForm.paramValue)
      ) {
        callback(this.$t('validate.required'));
      } else if (this.dataForm.paramType == 2 && !this.dataForm.paramList) {
        callback(this.$t('config.paramArrNotEmpty'));
      } else if (
        this.dataForm.paramType == 3 &&
        (!this.dataForm.paramTable ||
          !this.dataForm.paramTableKyes ||
          this.dataForm.paramTableKyes.length < 1)
      ) {
        callback(this.$t('config.paramTabNotEmpty'));
      }

      callback();
    };
    return {
      paramTypes: [
        { label: this.$t('config.label_string'), value: 1 },
        { label: this.$t('config.label_array'), value: 2 },
        { label: this.$t('config.label_table'), value: 3 }
      ],
      deleteRows: [],
      visible: false,
      loading: false,
      dataForm: {
        id: null,
        paramKey: "",
        paramValue: "",
        paramList: [],
        paramTable: [],
        paramTableKyes: [],
        remark: "",
        paramType: 1
      },
      dataRule: {
        paramKey: [
          { required: true, message: this.$t('validate.required'), trigger: "blur" }
        ],
        paramValue: [{ validator: validateValue, trigger: "blur" }]
      }
    };
  },
  // activated () {
  //   const id = this.$route.query.id
  //   this.init(id)
  //   this.loading = false
  // },
  methods: {
    init(id) {
      this.dataForm.id = id;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) { 
          getById(this.dataForm.id).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.paramKey = data.data.paramKey;
              this.dataForm.paramType = data.data["paramType"];
              this.dataForm.remark = data.data.remark;
              this.dataForm.paramValue = data.data.paramValue;
              if (!this.dataForm.paramType || this.dataForm.paramType == 1) {
                // 字符串
                this.dataForm.paramValue = data.data.paramValue;
              } else if (this.dataForm.paramType == 2) {
                // 数组
                this.dataForm.paramList = JSON.parse(data.data.paramValue);
              } else if (this.dataForm.paramType == 3) {
                // 表格
                this.dataForm.paramTable = JSON.parse(data.data.paramValue);
                // keys
                this.dataForm.paramTableKyes = [];
                if (
                  this.dataForm.paramTable &&
                  this.dataForm.paramTable.length > 0
                ) {
                  const zeroIndexTable = this.dataForm.paramTable[0];
                  for (let key in zeroIndexTable) {
                    this.dataForm.paramTableKyes.push(key);
                  }
                }
              }
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          let paramValue = this.dataForm.paramValue;
          if (this.dataForm.paramType == 2) {
            // 数组
            paramValue = JSON.stringify(this.dataForm.paramList);
          } else if (this.dataForm.paramType == 3) {
            // 表格
            paramValue = JSON.stringify(this.dataForm.paramTable);
          }

          this.loading = true
          const data_ = {
              id: this.dataForm.id || undefined,
              paramKey: this.dataForm.paramKey,
              paramValue: paramValue,
              remark: this.dataForm.remark,
              paramType: this.dataForm.paramType
            }
          saveOrUpdate(data_).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: this.$t('prompt.success'),
                  type: "success",
                  duration: 500
                });

                this.visible = false;
                this.$emit("refreshDataList");
              } else {
                this.$message.error(data.msg);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    addCol() {
      this.$prompt(this.$t('config.addNewFieldName'), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        inputPattern: /\S/,
        inputErrorMessage: this.$t('validate.required'),
      })
        .then(({ value }) => {
          this.dataForm.paramTableKyes.push(value);
          for (let i in this.dataForm.paramTable) {
            this.dataForm.paramTable[i][value] = "";
          }
        })
        .catch(() => {});
    },
    deleteTableHandle(index) {
      this.dataForm.paramTable.splice(index, 1);
    },
    deleteListHandle(index) {
      this.dataForm.paramList.splice(index, 1);
    },
    addRow() {
      this.dataForm.paramTable.push({});
    },
    delCol() {
      this.deleteRows.forEach(row => {
        this.delCol_bylabel(row);
      });

      this.deleteRows = [];
    },
    delCol_bylabel(label) {
      for (let i in this.dataForm.paramTableKyes) {
        const key = this.dataForm.paramTableKyes[i];
        if (key == label) {
          this.dataForm.paramTableKyes.splice(i, 1);
          for (let i in this.dataForm.paramTable) {
            delete this.dataForm.paramTable[i][label];
          }
          break;
        }
      }
    },

    addListCol() {
      this.$prompt(this.$t('config.inputNewValueArr'), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        inputPattern: /\S/,
        inputErrorMessage: this.$t('validate.required')
      })
        .then(({ value }) => {
          this.dataForm.paramList.push(value);
        })
        .catch(() => {});
    }
  }
};
</script>
